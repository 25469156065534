import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
const metaKeyword = {
    home: "Singapore warrants, structured warrants, Macquarie",
    warrantsearch: "warrant search, investment criteria, Singapore warrants",
    livematrix: "live matrix, live pricing, bid price",
    warrantterms: "warrant terms, Macquarie, Singapore warrants",
    calculator: "warrant calculator,  Macquarie, Singapore warrants",
    comparison: "warrant comparison, Singapore warrants, Macquarie",
    document: "listing, prospectus, term sheets",
    toptradedwarrants: "top traded warrants, top traded volume, active warrants",
    topgainer: "top gainers, top losers, price change, percentage change",
    turnoverdist: "warrant turnover, turnover distribution, top traded underlyings",
    turnover: "warrants market turnover, warrants turnover, Singapore warrants",
    moneyflow: "money flow, Singapore warrants, structured warrants",
    dwvideo: "warrant video, structured warrants, Macquarie",
    dwvideo_v: "Market View / Commentary, structured warrants, Macquarie",
    speaker: "guest speakers, call warrant, Macquarie",
    eduhtml: "warrant education, call warrant, Macquarie",
    indexdw: "index warrant, HSI warrants, S&P warrants, SiMSCI warrants",
    sp500: "index warrants, S&P warrants, structured warrants, Macquarie, SPX",
    faq: "call warrants, structured warrants, warrants FAQ",
    whydw28: "structured warrants, Singapore warrants, Macquarie",
    seminar: "call warrants, seminar, Singapore warrants",
    wheretotrade: "how to get started, where to trade warrants, Singapore warrants",
    telegram: "Singapore warrants, Telegram, Macquarie",
    game: "Singapore warrants, structured warrants, contest, warrants, 2020",
    round: "Singapore warrants, structured warrants, contest, warrants, 2020",
    hstech: "HSTECH, Hang Seng TECH Index, Index warrants",
    dailyhighlights: "daily market highlights, stock market, research",
    dailycommentary: "market insights, stock market, research",
    overnightmarkets: "S&P 500 Index, US stocks, global markets",
    subscribe: "newsletter, Singapore warrants, subscribe",
    activities: "Macquarie, events, Singapore warrants",
    photogallery: "Photos, Macquarie, Singapore warrants",
    SuccessSpeaks: 'Singapore Warrants, Success Speaks, Macquarie '
}
const metaDescription = {
    home: "See today's top traded index and single stock structured warrants in Singapore Warrants. Trading tools and stock market updates, all in one place.",
    warrantsearch: "Use our warrant search tool to find all structured warrants that match your investment criteria to aid in your decision-making.",
    livematrix: "Use our live matrix to make trading warrants easy. See where Macquarie's bid prices for index and single stock structured warrants will be throughout the day.",
    warrantterms: "Find out more about your selected structured warrant by viewing all warrant terms here.",
    calculator: "Use our warrant calculator to estimate your future structured warrant price given a change in underlying price, date to expiry or implied volatility.",
    comparison: "Compare multiple warrants and their key parameters at one glance for faster and more optimal decision-making.",
    document: "Gain access to all of Macquarie's listing documents, prospectus, and terms and conditions relevant to structured warrants issued by Macquarie.",
    toptradedwarrants: "View all top traded and most active index and single stock structured warrants with highest traded volume for the day.",
    turnoverdist: "Learn more about today's warrant turnover distribution for top traded warrant underlyings.",
    topgainloss: "View the warrants that gained / lost the most in both price and percentage terms here.",
    turnover: "Find out how active the warrants market was today compared to previous days.",
    moneyflow: "See what structured warrants other investors are buying and selling. Gain access to this exclusive information available weekly.",

    video: "Watch our animated series and our guest speaker videos to improve your knowledge in structured warrants now!",
    video_v: "Watch our animated series and our guest speaker videos to improve your knowledge in structured warrants now!",
    speaker: "Hear from our guest speakers about the latest market outlook",
    eduhtml: "Empower yourself with knowledge in structured warrants through our warrant education videos.",
    indexdw: "Find out how you can gain exposure to foreign markets via index warrants.",
    sp500: "Find out how you can gain an exposure to the U.S. equity market via the S&P 500 warrants by Macquarie.",
    faq: "Not familiar with warrants? Browse through the frequently asked questions and have all of your questions regarding warrants answered.",
    whydw28: "Macquarie is the leading warrants issuer in Asia with the largest market share in Singapore and Malaysia Learn more about our business strengths here.",
    seminar: "Register online on our website to attend warrant seminars for FREE!",
    getstarted: "Want to get started with trading warrants but not sure how? See our step by step guide now!",
    telegram: "Welcome to Macquarie Warrants. Take your first step towards learning about structured warrants now!",
    round: "We’re on Telegram and Facebook! Join our contest to celebrate. 10 vouchers worth $100 to be given away in this round",
    game: "We’re on Telegram and Facebook! Join our contest to celebrate. 10 vouchers worth $100 to be given away in this round",
    hstech: "Learn more about the technology focussed Hang Seng TECH Index and how to capture trading opportunities with Macquarie’s HSTECH warrants!",
    SuccessSpeaks: 'What does it take to be a warrant trader? Introducing Wong Kon How and Wang Tsu Wei, who are sharing their warrant trading secrets with you!',

    dailyhighlights: "Keep up to date with daily highlights on the stock market and other relevant news from Macquarie Equities Research and more.",
    dailycommentary: "Gain access to Macquarie's exclusive commentary on the stock market and other relevant news, updated by 6pm each day.",
    overnightmarkets: "Stay on top of the latest news in the global markets to ensure that you make the most informed decisions on any trading day.",
    subscribe: "Subscribe to Macquarie's daily newsletter. Start each day with the inside scoop on what's happening in the warrants market.",
    activities: "Check out the latest events and activities organised by Macquarie. ",
    photogallery: "Visit our photo gallery to get a closer look at all our recent events.",
}
class ScrollToTop extends Component {
    setdescription() {
        var keyword = '';
        var description = '';
        for (let i in metaKeyword) {
            if (window.location.href.indexOf(i) != -1) {
                keyword = metaKeyword[i]
            }
        }
        for (let i in metaDescription) {
            if (window.location.href.indexOf(i) != -1) {
                description = metaDescription[i]
            }
        }
        if (window.location.href.slice(window.location.href.lastIndexOf('/') + 1) == 'turnoverdist') {
            keyword = "warrant turnover, turnover distribution, top traded underlyings",
                description = "Learn more about today's warrant turnover distribution for top traded warrant underlyings."
        }
        if (window.location.href.slice(window.location.href.lastIndexOf('/') + 1) == 'turnover') {
            keyword = "warrants market turnover, warrants turnover, Singapore warrants",
                description = "Find out how active the warrants market was today compared to previous days."
        }
        document.querySelector("meta[name='description']").setAttribute('content', description);
        document.querySelector("meta[name='keywords']").setAttribute('content', keyword);
        var dom = document.getElementById('navbar')
        if (dom) dom.className = 'navmenu navbar-collapse collapse'
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
            this.setdescription()
        }
    }
    componentDidMount() {
        this.setdescription()
    }
    render() {
        return this.props.children
    }
}
export default withRouter(ScrollToTop);