import React, { Component } from "react";
import Select, { createFilter } from "react-select";
import classNames from "classnames";
import PageHeader from "../../../components/pageHeader/pageHeader";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
// import WarrantCalculator from "../warrantcalculator/calculator";
import AdvertBox from "../../../components/advert/advert";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { components } from "react-select";
import ReactToPrint from "react-to-print";
import "./livematrix.css";
import LiveIndexFuture from "../../../components/liveindexfuture/liveindexfuture";
import highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import nodata from "highcharts/modules/no-data-to-display";
import "../warrantterm/term.css";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import { LiveMatrixTodayTable, IndtableShow, TermsChart, WrCalculatorBox, LivematrixLogic } from "../../../components/WarrantTools";
export default class LiveMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      symbols: null,
      livematrixdata: null,
      isLoading: true,
      tabNum: 3,
      period: 'Y',
      chartData: [],
      isLoadingChart: false,
      underlying: [],

    };
    this.page = "livematrix";
    this.logic = null;
    this.ticker = null;
    this.initRedirect(props);
  }
  // onFetchUnderlyingSymbol() {
  //   const url = MQSGUtil.getAPIBasePath() + "/UnderlyingRankingJSON?mode=2";
  //   fetch(url)
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         this.setState({ underlying: result });
  //       },
  //       (error) => {
  //         this.setState({ underlying: [] });
  //       }
  //     );
  // }
  onUnderlyingChange(option) {
    const url = MQSGUtil.getAPIBasePath() + "/SymbolsJSON?ric=" + option.underlying_ric;
    this.refs.selectWarrant.refs.warrant.onMenuOpen()
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ symbols: result });
        },
      );
  }
  onPeriodChange(period) {
    if (this.state.livematrixdata !== null) {
      const ric = this.state.selected.value;
      const underlyingric = this.state.livematrixdata.ric_data.underlying_ric;
      if (underlyingric !== null) {
        // const selectedNew = { ...this.state.selected };
        this.setState({ period: period, chartdata: null });
        this.onFetchChartData(underlyingric, ric, period);
      }
    }
  }
  onFetchChartData(underlyingRic, ric, period = "Y") {
    if (MQSGUtil.isUsingStaticData("Warrantterms")) {
      setTimeout(() => {
        this.onFetchStaticChartData(underlyingRic, ric, period);
      }, 100); // pretend fetch
      return;
    }
    const isLoadingChart = false;
    const ricString = underlyingRic + ";" + ric;
    let chartdata = null;
    const url =
      MQSGUtil.getAPIBasePath() +
      "/alert?TYPE=JSON&RIC=" +
      ricString +
      "&PERIODICITY=" +
      period +
      "&d=" +
      Date.now();
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          chartdata = result;
          this.setState({ chartdata, isLoadingChart });
        },
        (error) => {
          this.setState({ chartdata, isLoadingChart });
        }
      );
  }
  componentDidMount() {
    if (!this.isHome()) {
      document.title = "Live matrix | Singapore Warrants | Macquarie"
      // this.onFetchUnderlyingSymbol()
      if (this.props.match.params.ticker) {
        let symbols = null;
        const url =
          MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=AEML_tm.SI&mode=0";
        fetch(url)
          .then((res) => res.json())
          .then(
            (result) => {
              if ("symbols" in result) {
                symbols = result.symbols;
                var tabNum = sessionStorage.getItem('tab1');
                if (!tabNum) {
                  tabNum = 3
                }
                this.setState({ symbols, tabNum });
                let check = symbols.filter((item) => {
                  if (item['security_code'] == this.props.match.params.ticker) {
                    item.label = item.dsply_nmll + " (" + item.security_code + ")";
                    item.value = item.ric;
                    return item
                  }
                })
                if (check.length > 0) {
                  var initselected = { label: check[0].label, value: check[0].value, future_dsply_name: check[0].future_dsply_name }
                  this.onWarrantChange(initselected);
                } else {
                  var initselected = this.initSelection(symbols);
                  this.onWarrantChange(initselected);
                }
              }
            },
            (error) => {
              this.setState({ symbols, isLoading: false });
            }
          );
      } else {
        this.onFetchSymbol();
      }
    } else {
      this.onFetchSymbol();
    }
  }
  setTab(i) {
    if (i != this.state.tabNum) {
      this.setState({
        tabNum: i
      }, () => {
        sessionStorage.setItem('tab1', i)
      })
    }
  }
  initRedirect(props) {
    const match = MQSGUtil.getData(props, "match", null);
    if (match !== null) {
      const params = MQSGUtil.getData(match, "params", null);
      if (params !== null) {
        const ticker = MQSGUtil.getData(params, "ticker", null);
        if (ticker !== null) {
          this.ticker = ticker.toUpperCase();
        }
      }
    }
  }

  initSelection(symbols) {
    let lastselection = MQSGUtil.getLastSelection(this.page);
    if (this.ticker !== null) {
      lastselection = null;
    }
    let selectedsymbol = null;
    let initsymbol = null;
    if (symbols !== null && Array.isArray(symbols)) {
      symbols.forEach((symbol) => {
        if ("ric" in symbol) {
          if (initsymbol === null) {
            initsymbol = symbol;
          }
          if (lastselection === symbol.ric) {
            selectedsymbol = symbol;
          }
        }
        if ("security_code" in symbol) {
          if (this.ticker === symbol["security_code"]) {
            selectedsymbol = symbol;
          }
        }
      });
    }
    if (selectedsymbol !== null) {
      initsymbol = selectedsymbol;
    }
    if (initsymbol !== null) {
      MQSGUtil.updateLastSelection(this.page, initsymbol.ric);
      if ("dsply_nmll" in initsymbol && "security_code" in initsymbol) {
        return {
          label: initsymbol.dsply_nmll + " (" + initsymbol.security_code + ")",
          value: initsymbol.ric,
          future_dsply_name: initsymbol.future_dsply_name
        };
      }
    }
    this.ticker = null;
    return null;
  }

  onFetchStaticSymbol() {
    let symbols = null;
    const staticData = MQSGUtil.getStaticData("LiveMatrix");
    if ("symbols" in staticData) {
      symbols = staticData.symbols;
      this.setState({ symbols });
      const initselected = this.initSelection(symbols);
      this.onWarrantChange(initselected);
    } else {
      this.setState({ symbols, isLoading: false });
    }
  }

  onFetchSymbol() {
    if (MQSGUtil.isUsingStaticData("LiveMatrix")) {
      this.onFetchStaticSymbol();
      return;
    }

    let symbols = null;
    const url =
      MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=AEML_tm.SI&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          if ("symbols" in result) {
            symbols = result.symbols;
            this.setState({ symbols });
            const initselected = this.initSelection(symbols);
            this.onWarrantChange(initselected);
          }
        },
        (error) => {
          this.setState({ symbols, isLoading: false });
        }
      );
  }

  onFetchStaticData(ric) {
    const isLoading = false;
    const staticData = MQSGUtil.getStaticData("LiveMatrix");
    if (ric in staticData) {
      let livematrixdata = staticData[ric];
      this.logic = new LivematrixLogic(
        livematrixdata.livematrix,
        livematrixdata.ric_data
      );
      this.setState({ livematrixdata, isLoading });
    } else {
      this.setState({ livematrixdata: null, isLoading });
    }
  }

  onFetchData(ric) {
    if (MQSGUtil.isUsingStaticData("LiveMatrix")) {
      setTimeout(() => {
        this.onFetchStaticData(ric);
      }, 100); // pretend fetch
      return;
    }
    const isLoading = false;
    const url =
      MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=" + ric + "&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const livematrixdata = result;
          this.logic = new LivematrixLogic(
            livematrixdata.livematrix,
            livematrixdata.ric_data
          );
          this.setState({ livematrixdata, isLoading }, () => {
            const underlyingric = this.state.livematrixdata.ric_data.underlying_ric;
            this.onFetchChartData(
              underlyingric,
              ric,
              this.state.period
            );
          });
        },
        (error) => {
          this.setState({ livematrixdata: null, isLoading });
        }
      );
  }

  onWarrantChange(selected) {
    if (selected !== undefined && selected !== null) {
      this.logic = null; // hide previous data
      this.setState({ selected, livematrixdata: null, isLoading: true });
      this.onFetchData(selected.value);

      MQSGUtil.updateLastSelection(this.page, selected.value);
    }
  }

  onRefresh() {
    this.onWarrantChange(this.state.selected);
  }

  getRicData() {
    if (this.state.livematrixdata !== null) {
      if ("ric_data" in this.state.livematrixdata) {
        return this.state.livematrixdata.ric_data;
      }
    }
    return null;
  }

  getLastUpdate() {
    if (this.state.livematrixdata !== null) {
      if ("last_update" in this.state.livematrixdata) {
        return this.state.livematrixdata["last_update"];
      }
    }
    return "-";
  }

  getSymbols() {
    if (this.state.symbols !== null) {
      if (Array.isArray(this.state.symbols)) {
        return this.state.symbols;
      }
    }
    return null;
  }

  getSelectedLabel() {
    if (this.state.selected === null) {
      return "";
    }
    return this.state.selected.label;
  }

  isLoading() {
    return this.state.isLoading;
  }

  isHome() {
    if (this.props.page !== undefined && this.props.page !== null) {
      if (this.props.page === "home") {
        return true;
      }
    }
    return false;
  }

  renderHome() {
    let isShow = true;
    var isSti = false;
    if (this.logic != null) {
      if (this.logic.isSTI) {
        isSti = true;
      } else {
        isSti = false;
      }
    }
    if (
      this.state.livematrixdata == null ||
      this.state.livematrixdata.livematrix.length === 0 ||
      isSti
    ) {
      isShow = false;
    }
    var code = '';
    if (this.state.selected != null) {
      code = this.state.selected.label.slice(this.state.selected.label.indexOf('(') + 1, this.state.selected.label.indexOf(')'))
    }
    return (
      <div className="holder-lmatrix" >
        <div id="lmatrix">
          <div className="page-header">
            <h2 className="small">Live matrix</h2>
          </div>
          <p className="section-p">
            Shows investors where our bid and offer prices are for a range of
            given underlying prices.
          </p>
        </div>
        <div>
          <Warrant
            symbols={this.getSymbols()}
            disabled={this.isLoading()}
            isHome={this.isHome()}
            onWarrantChange={(selected) => this.onWarrantChange(selected)}
          />
        </div>
        <div
          className="ricbar-home bgcolor-03"
          style={{
            // display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            marginTop: "10px",
            display: !isShow ? "none" : "flex",
            overflowX: 'auto'
          }}
        >
          <div
            className="ticker-home"
            id="ticker"
            style={{ paddingLeft: "10px", lineHeight: '35px' }}
          >
            {this.getSelectedLabel()}
          </div>
          <a href={"/tools/livematrix/" + code} style={{ display: "flex", alignItems: 'center' }} target="_blank">
            <p style={{ margin: "4px", lineHeight: "25px" }}>View more</p>
            <img src="/img/bullet/qlink-achor.png" alt="" style={{ height: 36, width: 36 }} />
          </a>
        </div>
        <div
          style={{
            display: isShow || !isSti ? "none" : "block",
            marginTop: "10px",
            fontStyle: "italic",
          }}
        >
          Please select a warrant to see its live matrix.
        </div>
        <LiveMatrixTodayTable logic={this.logic} page={this.props.page} isLoading={this.state.isLoading} />
      </div>
    );
  }
  childMethod() {
    if (this.refs.child) {
      this.refs.child.onClickTradeWarrantsHere()
    }
  }
  getTermHtml() {
    if (this.state.livematrixdata != null) {
      return (
        <div id="dwterms">
          <TermsSummary
            warrantdata={this.state.livematrixdata.ric_data}
            tabNum={this.state.tabNum}
          />
        </div>
      )
    }
  }
  renderPage() {
    let ric = null;
    let name = null;
    let isSpShow = false;
    let isWarrantsShow = false;
    let isUnderyingShow = false;
    let future_dsply_name = '';
    let spanShow = false;
    if (
      typeof this.state.selected !== "undefined" &&
      this.state.selected !== null
    ) {
      ric = this.state.selected.value;
      name = this.state.selected.label;
      if (
        this.state.selected.label.indexOf('S&P') != -1 ||
        this.state.selected.label.indexOf('DJI') != -1 ||
        this.state.selected.label.indexOf('HSI') != -1 ||
        this.state.selected.label.indexOf('HSTECH') != -1 ||
        this.state.selected.label.indexOf('NKY') != -1 ||
        this.state.selected.label.indexOf('SIMSCI') != -1 ||
        this.state.selected.label.indexOf('Sea') != -1 ||
        this.state.selected.label.indexOf('NASDAQ') != -1
      ) {
        isWarrantsShow = true;
      } else {
        if (this.state.selected.future_dsply_name && this.state.selected.future_dsply_name != '' && this.state.selected.future_dsply_name != '-') {
          isUnderyingShow = true;
          future_dsply_name = this.state.selected.future_dsply_name;
        }
      }
    }
    var isShow = false;
    if (this.state.tabNum == 2) {
      isShow = true
    } else {
      isShow = false
    }
    return (
      <div id="livematrix" className="pageobj" onClick={() => this.childMethod()}>
        <BreadcrumbBar
          link="/home"
          group="Warrant tools"
          title="Live matrix"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <PageHeader title="Live matrix" ref="child" />
                <div>
                  Shows investors where our bid and offer prices are for a range
                  of given underlying prices.{" "}
                  <span
                    className="ttip"
                    data-target="livematrixdescription"
                    data-hasqtip="0"
                    data-tip
                    data-for="indicator-hotdw-tooltip"
                    data-tooltip-id="indicator-hotdw-tooltip"
                  >
                    <mark>
                      Read on to find out how the matrix for put warrants differ
                      from calls
                    </mark>
                    <ReactTooltip
                      id="indicator-hotdw-tooltip"
                      delayHide={100}
                      place="right"
                      type="warning"
                      effect="solid"
                      backgroundColor="#FECC0A"
                      textColor="#000"
                      className="ttip_con"
                    >
                      <div
                        style={{
                          color: "#333333",
                        }}
                      >
                        Put prices move in the opposite direction to the share
                        price, for this reason the price matrices
                        <br />
                        are the opposite to what you would find for a call
                        warrant, where..
                        <br />
                        The{" "}
                        <span style={{ textDecoration: "underline" }}>
                          offer
                        </span>{" "}
                        price for the warrant is based on the bid price of the
                        underlying
                        <br />
                        The{" "}
                        <span style={{ textDecoration: "underline" }}>
                          bid{" "}
                        </span>
                        price for the warrant is based on the offer price of the
                        underlying
                      </div>
                    </ReactTooltip>
                  </span>
                </div>
                <p style={{ marginTop: "10px" }}>
                  The live matrix consists of a table showing a range of prices for the underlying’s bid/offer and warrant’s bid/offer.
                </p>
                <p style={{ marginTop: "10px" }}>
                  Call prices move in the same direction as the share price. The call warrant offer price is based on the underlying offer price while the bid price of the warrant is based on the underlying bid price.
                </p>
                <p style={{ marginTop: "10px" }}>
                  On the other hand, the offer price for a put warrant is based on the bid price of the underlying while the bid price for the warrant is based on the offer price of the underlying"
                </p>
                <div id="top_area">
                  {/* <Underlying
                    underlying={this.state.underlying}
                    onUnderlyingChange={(option) =>
                      this.onUnderlyingChange(option)
                    }
                  /> */}
                  <p style={{ margin: '15px 0px' }}></p>
                  <Warrant
                    ref='selectWarrant'
                    symbols={this.getSymbols()}
                    disabled={this.isLoading()}
                    onWarrantChange={(selected) =>
                      this.onWarrantChange(selected)
                    }
                  />

                  <div className="tablist">
                    <div id="topTabs" className="tablist-left" style={{ overflowX: 'auto' }}>
                      <table>
                        <tbody>
                          <tr>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 1,
                              })}
                              onClick={() => this.setTab(1)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Historical Performance
                                </div>
                              </div>
                            </td>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 4,
                              })}
                              onClick={() => this.setTab(4)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Warrant terms
                                </div>
                              </div>
                            </td>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 2,
                              })}
                              onClick={() => this.setTab(2)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Warrant Calculator
                                </div>
                              </div>
                            </td>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 3,
                              })}
                              onClick={() => this.setTab(3)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Live matrix
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {this.getTermHtml()}
                  <div style={{ display: this.state.tabNum == 3 ? 'block' : 'none' }}>
                    <p style={{ marginTop: '10px', marginBottom: '0px', display: isWarrantsShow ? 'block' : 'none' }}>
                      Underlying price below refers to the relevant Index Futures contract. Refer to the "Live index futures prices" on the home page to verify which Index Futures it is tracking.<br /> For US indices, it is typically tracking the quarterly month futures - reach us at +65 6601 0189 to confirm the month should you be unsure.
                    </p>
                    <p id="p-contract" style={{ marginTop: '10px', marginBottom: '0px', display: isUnderyingShow ? 'block' : 'none' }}>Underlying price below refers to the relevant Index Futures contract

                      <span> over</span>
                      <span className="text-bold"> {future_dsply_name}</span>
                    </p>
                  </div>
                  {
                    this.state.tabNum == 3 ? <LiveMatrixTodayTable logic={this.logic} isLoading={this.state.isLoading} selected={this.state.selected} /> : this.state.tabNum == 4 ? <IndtableShow
                      warrantdata={this.state.livematrixdata && this.state.livematrixdata.ric_data}
                    /> : null
                  }
                  <div style={{ display: this.state.tabNum == 1 ? 'block' : 'none' }}>
                    <TermsChart
                      selected={this.state.selected}
                      period={this.state.period}
                      chartdata={this.state.chartdata}
                      onPeriodChange={this.onPeriodChange.bind(this)}
                      warrantdata={this.state.livematrixdata && this.state.livematrixdata.ric_data}
                    />
                  </div>
                  {
                    this.state.tabNum == 2 && this.state.livematrixdata != null && <WrCalculatorBox isRightPanel={true} ric={ric} marketdata={this.state.livematrixdata && this.state.livematrixdata.ric_data} />
                  }

                </div>
                <div style={{ clear: "both" }}></div>
                <div id="bottom_area" className="livematrix_valid">
                  <div id="srtable" style={{ overflowX: 'unset' }}>
                    <div style={{ display: this.state.tabNum == 4 || this.state.tabNum == 3 ? 'none' : 'block', overflowX: 'auto' }}>
                      <LiveMatrixPriceTable ricdata={this.getRicData()} />
                    </div>
                    <div id="last_update">
                      Last update: <span>{this.getLastUpdate()}</span>
                    </div>
                    <p>
                      The above price matrix is a direct live feed from
                      Macquarie’s market making system. It indicates where the
                      market maker’s bids and offers may be based on various
                      price points in the stock or index, and as at the last
                      updated time stamp. Please manually refresh the live
                      matrix for the latest prices. Note that the live matrix
                      may not be accurate outside of market making hours. These
                      are:
                    </p>
                    <div style={{ overflowX: 'auto' }}>
                      <LiveMatrixMarketHourTable />
                    </div>
                  </div>
                  {/* 文字说明 */}
                  <TextDescrtion />
                </div>
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4">
              <LiveIndexFuture liveMatrix={true} isSti={this.state.selected} />
              <AdvertBox />
              <div id="qlink-dwvideo" className="holder-qlink-dwvideo ">
                <div className="videoplayer">
                  <div className="videoplayer-container">
                    <iframe
                      title="education video"
                      frameBorder="0"
                      allowFullScreen=""
                      className="dwvideo-iframe"
                      src="https://www.youtube.com/embed/_EqFjoCgE08?autoplay=0&index=0&rel=0&wmode=opaque"
                    ></iframe>
                  </div>
                </div>
              </div>
              {/* <WarrantCalculator isRightPanel={true} ric={ric} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.isHome()) {
      return this.renderHome();
    } else {
      return this.renderPage();
    }
  }
}

function LiveMatrixMarketHourTable() {
  return (
    <table
      className="markethourtbl table table-striped table-left"
      style={{ tableLayout: "auto", backgroundColor: "unset" }}
    >
      <tbody style={{ textAlign: 'left' }}>
        <tr>
          <th>Hong Kong stock warrants: </th>
          <td>
            9.30am to 11.58am,
            <br className="visible-xs" /><br className="visible-minpc" /> 1.00pm to 3.58pm{" "}
          </td>
        </tr>
        <tr>
          <th>HSI and HSTECH warrants:</th>
          <td>
            9.15am to 11.58am,
            <br className="visible-xs" /><br className="visible-minpc" /> 1.00pm to 4.28pm
          </td>
        </tr>
        <tr>
          <th>NKY warrants:</th>
          <td>
            9.00am to 11.58am,
            <br className="visible-xs" /><br className="visible-minpc" /> 1.00pm to 2.23pm,
            <br className="visible-xs" /><br className="visible-pc" /> 2.55pm to 4.58pm
          </td>
        </tr>
        <tr>
          <th>US index warrants:</th>
          <td>
            9.00am to 11:58am,
            <br className="visible-xs" /><br className="visible-minpc" /> 1:00pm to 4:58pm
          </td>
        </tr>
        <tr>
          <th>SIMSCI & Singapore stocks:</th>
          <td>
            9.00am to 11:58am,
            <br className="visible-xs" /><br className="visible-minpc" /> 1:00pm to 4:58pm
          </td>
        </tr>
      </tbody>
    </table>
  );
}


class LiveMatrixPriceTable extends Component {
  render() {
    const ricdata = this.props.ricdata;
    // const underlyingticker = MQSGUtil.getData(ricdata, "underlying_ticker");
    const underlyingticker =
      MQSGUtil.getUnderlyingTickerDisplayName(MQSGUtil.getData(ricdata, "underlying_ric"), MQSGUtil.getData(ricdata, "underlying_ticker"));
    const effectivegearing = MQSGUtil.getData(ricdata, "effective_gearing");
    const type = MQSGUtil.getData(ricdata, "type");
    const delta = MQSGUtil.getData(ricdata, "delta");
    const exerciseprice = MQSGUtil.getData(ricdata, "exercise_price");
    const impliedvolatility = MQSGUtil.getData(ricdata, "implied_volatility");
    const wrntpershare = MQSGUtil.getData(ricdata, "wrnt_per_share");
    const lasttradingdate = MQSGUtil.getData(ricdata, "last_trading_date");

    return (
      <table className="pricetbl table tableSize">
        <tbody>
          <tr>
            <th className="srtable-leftlbl bgcolor-01">Underlying</th>
            <td
              id="underlying_ticker"
              className="srtable-leftval bgcolor-01"
              style={{ border: 0 }}
            >
              {underlyingticker}
            </td>
            <th className="srtable-rightlbl bgcolor-02" style={{ border: 0 }}>
              Effective gearing(X)
            </th>
            <td id="effective_gearing" className="srtable-rightval bgcolor-02">
              {effectivegearing}
            </td>
          </tr>
          <tr>
            <th>Type</th>
            <td id="type">{type}</td>
            <th>Delta(%)</th>
            <td id="delta">{delta}</td>
          </tr>
          <tr>
            <th className="bgcolor-01">Strike</th>
            <td
              id="exercise_price"
              className="bgcolor-01"
              style={{ border: 0 }}
            >
              {exerciseprice}
            </td>
            <th className="bgcolor-02">Volatility(%)</th>
            <td id="implied_volatility" className="bgcolor-02">
              {impliedvolatility}
            </td>
          </tr>
          <tr>
            <th>Exercise ratio</th>
            <td id="wrnt_per_share">{wrntpershare}</td>
            <th>Last trading date</th>
            <td id="last_trading_date">{lasttradingdate}</td>
          </tr>
        </tbody>
      </table>
    );
  }
}


class Warrant extends Component {
  onChange(selected) {
    if (this.props.isHome) {
      this.props.onWarrantChange(selected);
    } else {
      // window.location.href = '/tools/livematrix/' + selected.label.slice(selected.label.indexOf('(') + 1, selected.label.indexOf(')'))
      window.history.replaceState(
        {},
        "",
        `/tools/livematrix/${selected.label.slice(selected.label.indexOf('(') + 1, selected.label.indexOf(')'))}`
      );
      this.props.onWarrantChange(selected);
    }
  }
  getDisplayName() {
    const symbols = [];
    if (this.props.symbols !== null && this.props.symbols.length > 0) {
      this.props.symbols.forEach((element) => {
        if (
          "ric" in element &&
          "dsply_nmll" in element &&
          "security_code" in element
        ) {
          const item = {
            label: element.dsply_nmll + " (" + element.security_code + ")",
            value: element.ric,
            future_dsply_name: element.future_dsply_name
          };
          symbols.push(item);
        }
      });
    }
    return symbols;
  }
  onMenuOpen() {
    this.refs.warrant.onMenuOpen()
  }
  filterOption(candidate, input) {
    return createFilter()(candidate, input);
  };
  render() {
    return (
      <div id="dropdown-warrant">
        <div>
          <Select
            className="center"
            placeholder="Select warrant"
            options={this.getDisplayName()}
            onChange={this.onChange.bind(this)}
            isDisabled={this.props.disabled}
            ref='warrant'
            styles={{
              option: (provided, state) => ({
                ...provided,
                textAlign: "center",
                ':hover': {
                  backgroundColor: '#1A73E8',
                }
              }),
              valueContainer: (provided) => ({
                ...provided,
                justifyContent: 'center'
              })
            }}
            filterOption={this.filterOption}
          // filterOption={createFilter({
          //   ignoreCase: true,
          //   ignoreAccents: true,
          //   trim: true,
          //   // matchFrom: 'any',
          //   matchFrom: 'start',
          // })}
          />
        </div>
      </div>
    );
  }
}

// class Underlying extends React.Component {
//   constructor(props) {
//     super(props);
//     this.underlyingOption = null;
//   }

//   onChange(selected) {
//     this.props.onUnderlyingChange(selected);
//   }
//   getUnderlyingOption() {
//     if (this.underlyingOption === null) {
//       if (this.props.underlying !== null) {
//         if ("list" in this.props.underlying) {
//           const symbolData = this.props.underlying.list;

//           if (Array.isArray(symbolData)) {
//             this.underlyingOption = [];
//             symbolData.forEach((data) => {
//               if ("underlying_ric" in data) {
//                 const displayname = MQSGUtil.getUnderlyingTickerDisplayName(
//                   data.underlying_ric,
//                   data.underlying_ticker,
//                   false
//                 );
//                 console.log(displayname)
//                 if (displayname !== null) {
//                   data["label"] = displayname;
//                   data["value"] = data.underlying_ticker;
//                   this.underlyingOption.push(data);
//                 }
//               }
//             });
//           }
//         }
//       }
//     }
//     return this.underlyingOption;
//   }
//   filterOption(candidate, input) {
//     var value = input.replace(/\s/g, "").toUpperCase();
//     var labelValue = candidate.label.replace(/\s/g, "").toUpperCase()
//     if (labelValue.indexOf(value) != -1) return true;
//     return createFilter()(candidate, input);
//   }
//   render() {
//     const customStyles = {
//       menu: (provided) => ({
//         ...provided,
//         zIndex: 10,
//         textAlign: "center",
//       }),
//       option: (provided, state) => ({
//         ...provided,
//         color: '#000',
//         backgroundColor: '#FFFFFF',
//         ':hover': {
//           backgroundColor: '#1A73E8',
//         },
//       }),
//       valueContainer: (provided) => ({
//         ...provided,
//         justifyContent: 'center'
//       })
//     };

//     return (
//       <div id="dropdown-underlying">
//         <div>
//           <Select
//             placeholder="Select underlying"
//             options={this.getUnderlyingOption()}
//             styles={customStyles}
//             onChange={this.onChange.bind(this)}
//             filterOption={this.filterOption}
//           />
//         </div>
//       </div>
//     );
//   }
// }

class TextDescrtion extends Component {
  render() {
    return (
      <div>
        <div id="lmatrix_tbl_f" className="hidden-sm hidden-xs">
          <ol>
            <li>
              The warrant prices in the live matrix are indicative. The actual
              price of the warrants in the market may be different from the
              prices displayed on the website due to factors such as 1) the
              liquidity and the volatility of the warrant and the underlying as
              well as the relevant market conditions 2) the buying and the
              selling that is driven by the supply and the demand in the market
              3) the delay in displaying the price 4) the various rights of the
              underlying and other factors that are beyond the control of
              derivative warrant’s issuer, the market maker, the relevant
              parties or the unexpected situations. Macquarie reserves the
              rights to consider the terms and the conditions on buying back or
              selling warrants on case-by-case basis, at its sole discretion.
            </li>
            <li>
              There may be occasional delays in the data feed and any
              information in the live matrix. Only continuous trading session
              prices are shown.
            </li>
            <li>
              The warrant price are provided for your personal use and is
              intended for information purpose only and does not constitute an
              offer, a solicitation, an advice, a recommendation or an
              indication to buy or sell the underlying securities or warrant.
              Macquarie shall not be liable for the accuracy or completeness of
              any information or for any loss or damage however caused arising
              in connection with any information, including without limitation
              any direct, indirect, special or consequential loss and loss of
              profits whether in part or in full.
            </li>
          </ol>
          <hr />
          <p className="small">
            All market data is delayed 15 mins unless otherwise indicated by
            timestamp.
          </p>
        </div>

        <div
          className="panel-group visible-xs-block visible-sm-block"
          id="defination"
        >
          <div className="panel panel-default">
            <div className="panel-heading">
              <a
                data-toggle="collapse"
                data-parent="#defination"
                href="#collapse_dwcompare3"
              >
                <span className="glyphicon glyphicon glyphicon-question-sign pull-right"></span>
                <div
                  id="collapse_title3"
                  className="panel-title text-center color-primary"
                >
                  Definition &amp; disclaimer
                </div>
              </a>
            </div>
            <div id="collapse_dwcompare3" className="panel-collapse collapse">
              <div id="lmatrix_tbl_f">
                <ol>
                  <li>
                    The warrant prices in the live matrix are indicative. The
                    actual price of the warrants in the market may be different
                    from the prices displayed on the website due to factors such
                    as 1) the liquidity and the volatility of the warrant and
                    the underlying as well as the relevant market conditions 2)
                    the buying and the selling that is driven by the supply and
                    the demand in the market 3) the delay in displaying the
                    price 4) the various rights of the underlying and other
                    factors that are beyond the control of derivative warrant’s
                    issuer, the market maker, the relevant parties or the
                    unexpected situations. Macquarie reserves the rights to
                    consider the terms and the conditions on buying back or
                    selling warrants on case-by-case basis, at its sole
                    discretion.
                  </li>
                  <li>
                    There may be occasional delays in the data feed and any
                    information in the live matrix. Only continuous trading
                    session prices are shown.
                  </li>
                  <li>
                    The warrant price are provided for your personal use and is
                    intended for information purpose only and does not
                    constitute an offer, a solicitation, an advice, a
                    recommendation or an indication to buy or sell the
                    underlying securities or warrant. Macquarie shall not be
                    liable for the accuracy or completeness of any information
                    or for any loss or damage however caused arising in
                    connection with any information, including without
                    limitation any direct, indirect, special or consequential
                    loss and loss of profits whether in part or in full.
                  </li>
                </ol>
                <hr />
                <p className="small">
                  All market data is delayed 15 mins unless otherwise indicated
                  by timestamp.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


class TableGeneral extends Component {
  getData(fieldName, dp = null) {
    if (
      this.props.warrantdata !== null &&
      fieldName in this.props.warrantdata
    ) {
      const value = this.props.warrantdata[fieldName];
      if (dp !== null) {
        return Number(value).toFixed(dp);
      }
      return value;
    }
    return "";
  }

  getTheta() {
    // "last_trading_date": "23 Feb 21"
    const lasttradingdate = this.getData("last_trading_date");
    const currDate = new Date();
    const currDateMS = currDate.getTime();
    if (lasttradingdate.length > 0) {
      const lasttradingdateMS = Date.parse(lasttradingdate);
      const diff = currDateMS - lasttradingdateMS;
      // check if expired
      if (diff <= 24 * 60 * 60 * 1000) {
        return this.getData("theta", 4);
      }
    }
    return "-";
  }

  getMoneynessClass(nc) {
    let cnm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        cnm = "val mny up";
      } else if (value === 2) {
        cnm = "val mny down";
      }
    }
    return cnm;
  }

  getMoneynessName(nc) {
    let nm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        return "ITM";
      } else if (value === 2) {
        return "OTM";
      } else {
        return "ATM";
      }
    }
    return nm;
  }

  render() {
    const hstclose = this.getData("HST_CLOSE");
    const trdprc1 = this.getData("TRDPRC_1");
    const openprc = this.getData("OPEN_PRC");
    const acvol1scaled = this.getData("ACVOL_1_SCALED");
    const high1 = this.getData("HIGH_1");
    const turnoverscaled = this.getData("TURNOVER_SCALED");
    const low1 = this.getData("LOW_1");
    const underlyingprice = this.getData("underlying_price");
    const ticker = this.getData("ticker");
    const effectivegearing = this.getData("effective_gearing");
    const underlyingticker = this.getData("underlying_ticker");
    const delta = this.getData("delta");
    const type = this.getData("type");
    const deltaperwrnt = this.getData("delta_per_wrnt");
    const issuername = this.getData("issuer_name");
    const sensitivity = this.getData("sensitivity");
    const exerciseprice = this.getData("exercise_price");
    const breakevenprice = this.getData("breakeven_price");
    const tradedate = this.getData("TRADE_DATE");
    const premium = this.getData("premium");
    const maturity = this.getData("maturity");
    const impliedvolatility = this.getData("implied_volatility");
    const lasttradingdate = this.getData("last_trading_date");
    const theta = this.getTheta();
    const daystomaturity = this.getData("days_to_maturity") + " days";
    const intrinsicvalue = this.getData("intrinsic_value");
    const convratio = this.getData("conv_ratio");
    const moneyness = this.getData("moneyness");
    const moneynessClass = this.getMoneynessClass(moneyness);
    const moneynessdisplaystring =
      this.getData("percent_moneyness") +
      "% " +
      this.getMoneynessName(moneyness);

    return (
      <div>
        <div id="srtable" style={{ position: "relative" }}>
          <table className="table priorTable">
            <tbody>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Prior
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HST_CLOSE"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {hstclose}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Last trade
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TRDPRC_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {trdprc1}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ border: 0, textAlign: 'left' }}>
                  Open
                </th>
                <td
                  className="srtable-leftval val OPEN_PRC"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {openprc}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Traded volume ('000)
                </th>
                <td className="srtable-rightval val ACVOL_1_SCALED" style={{ textAlign: 'left' }}>
                  {acvol1scaled}
                </td>
              </tr>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left', textAlign: 'left' }}
                >
                  High
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HIGH_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {high1}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Traded value ('000 SGD)
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TURNOVER_SCALED"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {turnoverscaled}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ textAlign: 'left' }}>Low</th>
                <td className="srtable-leftval val LOW_1" style={{ border: 0, textAlign: 'left' }}>
                  {low1}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Underlying price
                </th>
                <td
                  className="srtable-rightval val underlying_price"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {underlyingprice}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
class TermsSummary extends Component {
  getData(data, fieldName) {
    if (data !== null && fieldName in data) {
      return data[fieldName];
    }
    return "";
  }

  // Returning either "upval", "downval", or "" as the CSS class for displaying these up, down or no-change value
  getUpDownClass(nc) {
    if (nc) {
      const ncstring = nc.toString();
      const ncval = Number(nc);

      // Check if 0
      if (ncval === 0) {
        return "";
      }
      // If -nnn ?
      else if (ncstring.length > 1 && ncstring.startsWith("-")) {
        return "val down";
      }
      // If +nnn ?
      else if ((ncstring.length > 1 && ncstring.startsWith("+")) || ncval > 0) {
        return "val up";
      }
    }

    // Any other case, return ""
    return "";
  }

  render() {
    var show = 'table';
    if (this.props.tabNum == 1 || this.props.tabNum == 3) {
      show = 'none'
    }
    const warrantdata = this.props.warrantdata;
    const ticker =
      this.getData(warrantdata, "dsply_nmll") +
      " (" +
      this.getData(warrantdata, "ticker") +
      ")";
    const bidval = this.getData(warrantdata, "BID");
    const bidchg = this.getData(warrantdata, "BID_NETCHNG");
    const bidchgupdown = this.getUpDownClass(bidchg);
    const bidpchg = this.getData(warrantdata, "BID_PCTCHNG") + "%";
    const bidpchgupdown = this.getUpDownClass(bidpchg);

    return (
      <div id="dwsummary" style={{ position: "relative" }}>
        <div className="ricbar">
          <div id="ticker" className="tickerbar bgcolor-03">
            {ticker}
          </div>
          {/* <div>
            <a
              href="/tools/livematrix"
              id="viewlm"
              type="button"
              className="ricbar-btn btn btn-block btn-primary"
            >
              View live matrix
            </a>
          </div> */}
        </div>
        <table id="pricetbl" className="uppertbl" style={{ display: show }}>
          <colgroup>
            <col style={{ backgroundColor: "#E7E8E8", padding: "3px" }} />
            <col
              style={{
                backgroundColor: "#F5F6F7",
                padding: "3px",
                textAlign: "left",
              }}
            />
            <col
              style={{
                backgroundColor: "#F5F6F7",
                padding: "3px",
                textAlign: "right",
              }}
            />
          </colgroup>
          <tbody>
            <tr>
              <td className="uppertbl-col-bid" rowSpan="2" style={{ textAlign: 'left' }}>
                <div className="bidprice lbl">Bid price</div>
                <div id="bidval" className="bidprice val">
                  {bidval}
                </div>
              </td>
              <td className="uppertbl-col-chglbl">Change</td>
              <td id="bidchg" className={"uppertbl-col-chgval " + bidchgupdown}>
                {bidchg}
              </td>
            </tr>
            <tr>
              <td className="uppertbl-col-pchglbl">%Change</td>
              <td
                id="bidpchg"
                className={"uppertbl-col-pchgval " + bidpchgupdown}
              >
                {bidpchg}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
