import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import MQSGUtil from './components/mqsgUtil'

// import 'bootstrap/dist/css/bootstrap-theme.css';
// import "../public/js/jquery/jquery-3.6.0.min.js"
import 'bootstrap/dist/css/bootstrap.css';//boostrap
import  "bootstrap-slider/dist/css/bootstrap-slider.css"
import 'bootstrap/dist/js/bootstrap.js';



import 'print-this'
//import 'bootstrap-slider/dist/bootstrap-slider.js'
import "react-datepicker/dist/react-datepicker.css";
import '../public/css/global.css';//先放bootstrap 再放自身css 样式就不会被覆盖

const getScreenerJSONServlet = async()=>{
    const url = MQSGUtil.getAPIBasePath() + "/ScreenerJSONServlet/ric";
      let ScreenerJSONServlet = await fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          return result
        }
      );
      window.localStorage.setItem('UnderlyingtickerdisplaynameMap',JSON.stringify(ScreenerJSONServlet))
      ReactDOM.render(<App />,document.getElementById('root'));
  }
getScreenerJSONServlet()



