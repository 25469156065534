import React, { Component } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import PageHeader from "../../../components/pageHeader/pageHeader";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import tableConfig from "./searchConfig";
import { components } from "react-select";
import "./search.css";
import LoaderSpin from "../../../components/LoaderSpin";

import { withRouter } from "react-router-dom";

const type = [
  { value: "Call/Put", label: "Call/Put" },
  { value: "call", label: "Call" },
  { value: "put", label: "Put" },
];

const moneyness = [
  { value: "all", label: "All moneyness" },
  { value: "atm", label: "At the money" },
  { value: "itm", label: "In the money" },
  { value: "otm", label: "Out of the money" },
];

const maturity = [
  { value: "all", label: "All maturity" },
  { value: "lt1", label: "< 1 month" },
  { value: "btw1t3", label: "1-3 months" },
  { value: "btw3t6", label: "3-6 months" },
  { value: "btw6t9", label: "6-9 months" },
  { value: "mt9", label: "> 9 months" },
];

const effectiveGearing = [
  { value: "all", label: "All Eff. gearing" },
  { value: "ls5", label: "< 5x" },
  { value: "btw5t10", label: "5-10x" },
  { value: "btw10t15", label: "10-15x" },
  { value: "mt15", label: "> 15x" },
];

const indicator = [
  { value: "all", label: "All indicators" },
  { value: "HIGHLQDY", label: "Highest liquidity" },
  { value: "APPCHEXPRY", label: "Near Expiry" },
  { value: "HIGHRESP", label: "Responsive" },
  { value: "HOTLIST", label: "Trending warrants" },
  { value: "SOLDOUT", label: "Inventory sold-out" },
  { value: "LOWTIMEDECAY", label: "Low time decay" },
];
const MenuList = (props) => {
  // var { onRicChange, onMenuClose } = props.selectProps;
  const { onSelectionChange } = props.selectProps;
  const getValue = (i, index) => {
    // onRicChange(i);
    // onMenuClose()
    onSelectionChange('issuer', i, index)
  };
  return (
    <components.MenuList {...props}>
      {props.options.map((i, index) => {
        return (
          <div
            key={i.label}
            className="selectSption00"
            style={{
              textAlign: "left",
              padding: "5px 10px",
              cursor: "pointer",
            }}

          >
            <input
              type="checkbox"
              value='666'
              style={{ marginRight: '10px' }}
              checked={i.check}
              onChange={(e) => {
                getValue(i, index);
              }} />
            {i.label}
          </div>
        );
      })}
    </components.MenuList>
  );
};
class WarrantSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      underlying: "all",
      maturity: "all",
      expiry: "All expiry",
      type: "Call/Put",
      effectiveGearing: "all",
      indicator: "all",
      moneyness: "all",
      issuer: "MBL",
      moneynessPercent: "all",
      sortBy: "",
      sortOrder: "",
      data: null,
      initData: {
        underlyingList: [
          {
            value: "all",
            label: "All underlying",
            underlying_ticker: "all",
            underlying_name: "all",
          },
          { value: "Indices", label: "Indices", underlying_ticker: 'Indices', underlying_name: 'Indices' },
          { value: "hkstocks", label: "Hong Kong stocks", underlying_ticker: 'hkstocks', underlying_name: 'Hong Kong stocks' },
          { value: "sgstocks", label: "Singapore stocks", underlying_ticker: 'sgstocks', underlying_name: 'Singapore stocks' },
        ],
        issuerList: [{ value: "all", label: "All issuers" }],
        expiryDateList: [{ value: "all", label: "All expiry" }],
      },
      isLoading: true,
      isLoading2: true,
    };
    this.initRedirect(props, this.state);
  }

  componentDidMount() {
    if (this.props.page !== "home") {
      if (this.props.history.location.search === "") {
        this.onFetchInitData();
      } else {
        let search = decodeURI(this.props.history.location.search);
        let first = search.indexOf("?");
        search = search.slice(first + 1);
        search = search.split(",");
        this.setState(
          {
            underlying: search[0].replace('"', ""),
            type: search[1].replace('"', "") == 'call' ? 'Call' : search[1].replace('"', "") == 'put' ? 'Put' : 'Call/Put',
            expiry: search[2].replace('"', "") == 'all' ? 'All expiry' : search[2].replace('"', ""),
          },
          () => {
            this.onFetchInitData();
          }
        );
      }
    } else {
      this.onFetchInitData();
    }
    if (!this.isHome()) {
      document.title = "Warrant Search Tool | Singapore Warrants | Macquarie"
    }
  }

  initRedirect(props, state) {
    const match = MQSGUtil.getData(props, "match", null);
    if (match !== null) {
      const params = MQSGUtil.getData(match, "params", null);
      if (params !== null) {
        const ticker = MQSGUtil.getData(params, "ticker", null);
        if (ticker !== null) {
          state["underlying"] = ticker;
        }
      }
    }
  }

  handleInitData(initData) {
    if (typeof initData !== "undefined" && initData !== null) {
      const initDataNew = { ...this.state.initData };
      const issuers = MQSGUtil.getData(initData, "issuers", null);
      if (issuers !== null && Array.isArray(issuers)) {
        issuers.forEach((issuer) => {
          if (issuer !== "MBL") {
            initDataNew["issuerList"].push({ value: issuer, label: issuer });
          } else {
            initDataNew["issuerList"].push({ value: issuer, label: "MACQ" });
          }
        });
      }
      const expiryDates = MQSGUtil.getData(initData, "expiryDates", null);
      if (expiryDates !== null && Array.isArray(expiryDates)) {
        expiryDates.forEach((expiryDate) => {
          initDataNew["expiryDateList"].push({
            value: expiryDate,
            label: expiryDate,
          });
        });
      }
      const underlyings = MQSGUtil.getData(initData, "underlyings", null);
      if (underlyings !== null && Array.isArray(underlyings)) {
        underlyings.forEach((underlying) => {
          const underlying_ticker = MQSGUtil.getData(
            underlying,
            "underlying_ticker"
          );
          const underlying_ric = MQSGUtil.getData(
            underlying,
            "underlying_ric"
          );
          const underlying_name = MQSGUtil.getData(
            underlying,
            "underlying_name"
          );
          const label =
            MQSGUtil.getUnderlyingTickerDisplayName(underlying_ric, underlying_ticker);
          initDataNew["underlyingList"].push({
            underlying_ticker,
            underlying_name,
            label,
            value: underlying_ticker,
          });
        });
      }
      this.setState({ initData: initDataNew, isLoading: false });
    }
  }

  onFetchStaticInitData() {
    const staticData = MQSGUtil.getStaticData("WarrantSearch");
    if ("init" in staticData) {
      const initData = staticData.init;
      this.handleInitData(initData);
      this.onFetchStaticData();
    }
  }

  onFetchInitData() {
    if (MQSGUtil.isUsingStaticData("WarrantSearch")) {
      this.onFetchStaticInitData();
      return;
    }
    let url = MQSGUtil.getAPIBasePath() + "/ScreenerJSONServlet?init=1";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const initData = result;
          this.handleInitData(initData);
          this.onFetchData();
        },
        (error) => { }
      );
  }

  onSelectionChange(source, selected) {
    if (source in this.state) {
      let value = selected.value;
      if (source === "underlying") {
        value = selected.underlying_ticker;
      }
      if (this.state[source] !== value) {
        const optionNew = {};
        optionNew[source] = value;
        this.setState(optionNew);
      }
    }
  }

  onSubmit() {
    this.onFetchData();
  }

  onHandleData(jsonData) {
    let data = null;
    if ("data" in jsonData) {
      if (Array.isArray(jsonData.data)) {
        data = jsonData.data;
      }
    }
    return data;
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("WarrantSearch");
    if ("underlyingAll" in staticData) {
      const data = this.onHandleData(staticData.underlyingAll);
      if (this.state.underlying === "all") {
        this.setState({ data });
      } else {
        this.setState({ data: null });
      }
    }
  }

  onFetchData(source = null, value = null) {
    if (MQSGUtil.isUsingStaticData("WarrantSearch")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    this.setState({ isLoading2: true })
    let url = "/ScreenerJSONServlet?";
    const paramNames = Object.keys(this.state);
    const param = [];
    paramNames.forEach((key) => {
      if (key !== "data" && key !== "initData") {
        if (key === source) {
          param.push(source + "=" + value);
        } else {
          if (key == 'underlying') {
            if (this.state[key].indexOf('&') !== -1) {
              param.push(key + "=" + this.state[key].replace('&', '%26'));
            } else {
              param.push(key + "=" + this.state[key]);
            }
          } else if (key == 'type') {
            if (this.state[key] == 'Call/Put') {
              param.push(key + "=" + 'all');
            } else {
              param.push(key + "=" + this.state[key]);
            }
          } else if (key == 'expiry' && this.state[key] == 'All expiry') {
            param.push(key + "=" + 'all');
          } else {
            param.push(key + "=" + this.state[key]);
          }
        }
      }
    });
    url += param.join("&");
    url = MQSGUtil.getAPIBasePath() + url;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const data = this.onHandleData(result);
          data.forEach(e => {
            e.issuer = 'MACQ'
          })
          this.setState({ data, isLoading2: false });
        },
        (error) => {
          this.setState({ data: null, isLoading2: false });
        }
      );
  }
  onJump() {
    this.props.history.push({
      pathname: "/tools/warrantsearch",
      state: {
        underlying: this.state.underlying,
        type: this.state.type,
        expiry: this.state.expiry,
      },
    });
  }
  //字母排序
  sortLetter(data, key, isAsc) {
    if (isAsc) {
      //升序
      data.sort(function (a, b) {
        return (a[key] + '').localeCompare(b[key] + '');
      })
      return data;
    }
    //降序
    data.sort(function (a, b) {
      return (b[key] + '').localeCompare(a[key] + '');
    })
    return data;
  }
  //数字排序
  sortNumber(data, key, isAsc) {
    if (isAsc) {
      //升序
      return data.sort(function (a, b) {
        let x = Number(a[key]);
        let y = Number(b[key]);
        return x < y ? -1 : x > y ? 1 : 0;
      })
    }
    //降序
    return data.sort(function (a, b) {
      let x = Number(a[key]);
      let y = Number(b[key]);
      return x < y ? 1 : x > y ? -1 : 0;
    })
  }
  // 将年月日转换为时间戳
  chgFormatDate(val) {
    let monData = { 'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12' }
    let getD = new Date();
    let getY = String(getD.getFullYear());
    getY = getY.slice(0, 2);
    if (val && val !== "-" && typeof (val) !== "undefined" && val != 0) {
      let arr = val.split(" ");
      let newDate = getY + arr[2] + '-' + monData[arr[1]] + '-' + arr[0];
      return Date.parse(newDate);
    }
    return '-'
  }
  onSorting(field, isAsc) {
    // local sort logic > field.name
    // api request sort > sortby
    this.setState({ sortBy: field, sortOrder: isAsc }, () => {
      this.onSubmit()
    })
    const { data } = this.state;
    // if (data !== null) {
    //   let getData = "";
    //   if (field["datatype"] === "string") {
    //     getData = this.sortLetter(data, field["name"], isAsc);
    //   } else if (field.datatype === "date") {
    //     for (let i in data) {
    //       data[i]["ltDate_temp"] = this.chgFormatDate(data[i]["ltDate"]);
    //       getData = this.sortNumber(data, "ltDate_temp", isAsc);
    //     }
    //   } else {
    //     getData = this.sortNumber(data, field["name"], isAsc);
    //   }
    //   this.setState({ data: getData });
    //   return
    // }
  }

  isHome() {
    if (this.props.page !== undefined && this.props.page !== null) {
      if (this.props.page === "home") {
        return true;
      }
    }
    return false;
  }

  renderHome() {
    return (
      <div className="holder-qlink-dwsearch">
        <div id="qlink-dwsearch">
          <div className="page-header">
            <h2 className="small">Warrant search</h2>
          </div>
          <p className="section-p">
            Find warrants according to your investment criteria.
          </p>
          <SearchGroup
            page="home"
            initData={this.state.initData}
            onSelectionChange={this.onSelectionChange.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            onJump={this.onJump.bind(this)}
            underlying={this.state.underlying}
            type={this.state.type}
            expiry={this.state.expiry}
          />
        </div>
      </div>
    );
  }
  childMethod() {
    if (this.refs.child) {
      this.refs.child.onClickTradeWarrantsHere()
    }
  }
  renderPage() {
    return (
      <div id="dwsearch" className="pageobj" onClick={() => this.childMethod()}>
        <BreadcrumbBar
          link="/home"
          group="Warrant tools"
          title="Warrant search"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="section">
            <PageHeader title="Warrant search" ref="child" />
            <div className="row">
              <div className="col-sm-6" style={{ paddingLeft: 0 }}>
                <p className="section-txt">
                  Find warrants according to your investment criteria.
                  <br />
                  For more information on how to select a warrant,&nbsp;
                  <a href="/InvestorAcademy/howtoselectawarrant" target="_blank" style={{ color: '#0675C2' }}>
                    click here
                  </a>
                  .
                </p>
              </div>
              <div className="col-sm-6">
                <a href="/tools/warrantcalculator" target="_blank"   >
                  <img
                    style={{ width: "100%" }}
                    src="/img/bg/warrantCalculatorBanner.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="filter-groud">
              <SearchGroup
                initData={this.state.initData}
                initUnderlying={this.state.underlying}
                initType={this.state.type}
                initExpiry={this.state.expiry}
                onSelectionChange={(source, selected) =>
                  this.onSelectionChange(source, selected)
                }
                onSubmit={this.onSubmit.bind(this)}
              />
            </div>
            <div className="indicator hidden-xs hidden-sm">
              <IconMeanning />
            </div>
            <TableResult
              config={tableConfig}
              isLoading={this.state.isLoading2}
              result={this.state.data}
              onSorting={this.onSorting.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }
    if (this.isHome()) {
      return this.renderHome();
    } else {
      return this.renderPage();
    }
  }
}

class Dropdown extends React.Component {
  onChange(selected) {
    this.props.onSelectionChange(this.props.source, selected);
  }

  render() {
    var str =
      this.props.placeholder === "select underlying" ? "center" : "left";
    var bgm = this.props.placeholder === "select underlying" ? '#1A73E8' : '#F5F5F5';
    const customStyles = {
      menu: (provided) => ({
        ...provided,
        textAlign: str,
        ':hover': {
          backgroundColor: str == 'center' ? 'yellow' : 'red',
        }
      }),
    };
    return (
      <div id="search-dropdown">
        <div style={{ marginBottom: "10px" }}>
          <Select
            placeholder={this.props.placeholder}
            options={this.props.options}
            // styles={customStyles}
            styles={{
              option: (provided, state) => ({
                ...provided,
                textAlign: 'left',
                cursor: 'pointer',
                // backgroundColor: '#FFFFFF',
                backgroundColor: state.isFocused
                  ? "#FFFFFF"
                  : ["1", "2", "3"].includes(state.innerProps.id.split("-")[4]) && str == 'center'
                    ? "#FECC0A"
                    : null,
                color: '#000',
                fontSize: '14px',
                ':hover': {
                  backgroundColor: str == 'center' ? '#1A73E8' : '#F5F5F5',
                },
              }),
              valueContainer: (provided) => ({
                ...provided,
                justifyContent: 'center',
                marginLeft: '25px'
              })
            }}
            onChange={this.onChange.bind(this)}
            defaultValue={this.props.default}
          />
        </div>
      </div>
    );
  }
}

class SearchGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issuerList: [],
      check: ''
    };
  }
  onSelectionChange(source, selected, index) {
    if (source == 'issuer') {
      const list = [...this.state.issuerList]
      if (selected.value == 'all') {
        list.map((itm) => {
          if (itm.value == 'all') {
            itm.check = !itm.check
          } else {
            itm.check = list[0].check
          }

        })
      } else {
        if (selected.check == false) {
          list.map((itm) => {
            if (itm.value == 'all') {
              itm.check = true
            }
          })
        } else {
          list.map((itm) => {
            if (itm.value == 'all') {
              itm.check = false
            }
          })
        }
        list[index].check = !list[index].check
      }
      this.setState({
        issuerList: list
      })
      return;
    }
    this.props.onSelectionChange(source, selected);
  }
  onMenuClose() {
    var list = [...this.state.issuerList]
    var bel = '';
    list.forEach((item) => {
      if (item.value == 'all') {
        if (item.check == true) {
          bel = true;
        } else {
          bel = false;
        }
      }
    })
    if (bel) {
      var selected = {
        value: "all",
        label: "All issuers"
      }
      this.refs.selectRef.setState({
        inputValue: "All issuers"
      })
    } else {
      var selected = {
        value: "MBL",
        label: "MACQ",
        check: true
      }
      this.refs.selectRef.setState({
        inputValue: "MACQ"
      })
      list = [
        { check: false, label: "All issuers", value: "all" },
        { check: true, label: "MACQ", value: "MBL" }
      ]
    }
    this.setState({
      issuerList: list
    })
    this.props.onSelectionChange('issuer', selected);
  }
  componentDidMount() {
    var list = [...this.props.initData.issuerList]
    list.map((item) => {
      item.check = false
    })
    if (list.length > 1) {
      list.map((item) => {
        if (item.label == "MACQ") {
          item.check = true;
        }
      })
    } else {
      list[0].check = true
    }
    this.setState({
      issuerList: list
    })
  }
  onSubmit() {
    this.props.onSubmit();
  }
  onJump() {
    this.props.onJump();
  }
  isHome() {
    if (this.props.page !== undefined && this.props.page !== null) {
      if (this.props.page === "home") {
        return true;
      }
    }
    return false;
  }

  getUnderlying(initData) {
    if (initData !== null) {
      const underlyingList = MQSGUtil.getData(initData, "underlyingList", null);
      if (underlyingList !== null) {
        return underlyingList;
      }
    }
    return [
      {
        value: "all",
        label: "All underlying",
        underlying_ticker: "all",
        underlying_name: "all",
      },
    ];
  }
  getInitUnderlying(underlyingList) {
    let initUnderlying = null;
    if (
      typeof this.props.initUnderlying !== "undefined" &&
      this.props.initUnderlying !== null
    ) {
      underlyingList.forEach((underlying) => {
        const value = MQSGUtil.getData(underlying, "value");
        if (value === this.props.initUnderlying) {
          initUnderlying = underlying;
        }
      });
    }
    if (initUnderlying === null) {
      return underlyingList[0];
    }
    return initUnderlying;
  }

  getExpiry(initData) {
    if (initData !== null) {
      const expiryDateList = MQSGUtil.getData(
        this.props.initData,
        "expiryDateList",
        null
      );
      if (expiryDateList !== null) {
        return expiryDateList;
      }
    }
    return [{ value: "All expiry", label: "All expiry" }];
  }

  getIssuer(initData) {
    if (initData !== null) {
      const issuerList = MQSGUtil.getData(
        this.props.initData,
        "issuerList",
        null
      );
      if (issuerList !== null) {
        return issuerList;
      }
    }
    return [{ value: "all", label: "All issuers" }];
  }

  getInitIssuer(issuer) {
    if (issuer.length > 1) {
      var dom = document.querySelector('.css-1wa3eu0-placeholder')
      if (dom) {
        // dom.innerHTML = issuer[1].label;
        dom.innerHTML = 'MACQ';
      }
      return issuer[1];
    }
    var dom = document.querySelector('.css-1wa3eu0-placeholder');
    if (dom) {
      dom.innerHTML = issuer[1].label;
    }
    return issuer[0];
  }

  renderHome() {
    let initData = null;
    if (
      typeof this.props.initData !== "undefined" &&
      this.props.initData !== null
    ) {
      initData = this.props.initData;
    }
    const underlyingList = this.getUnderlying(initData);
    const initUnderlying = this.getInitUnderlying(underlyingList);
    const expiry = this.getExpiry(initData);

    return (
      <>
        <div className="row">
          <div className="col">
            <Dropdown
              source="underlying"
              placeholder="select underlying"
              default={initUnderlying}
              options={underlyingList}
              onSelectionChange={(source, selected) =>
                this.onSelectionChange(source, selected)
              }
            />
          </div>
          <div className="col">
            <Dropdown
              source="type"
              placeholder="select Call/Put"
              default={type[0]}
              options={type}
              onSelectionChange={(source, selected) =>
                this.onSelectionChange(source, selected)
              }
            />
          </div>
          <div className="col">
            <Dropdown
              source="expiry"
              placeholder="select expiry"
              default={expiry[0]}
              options={expiry}
              onSelectionChange={(source, selected) =>
                this.onSelectionChange(source, selected)
              }
            />
          </div>
        </div>
        <div className="section-btn-wapper section-center section-btn-wapper-mobile">
          {/* <button
            id="searchSubmitBtn"
            type="button"
            className="btn btn-primary searchSubmitBtn"
            onClick={this.onJump.bind(this)}
          >
            Submit
          </button> */}
          {/* to="/tools/warrantsearch" */}
          <Link
            to={{
              pathname: "/tools/warrantsearch",
              // state: {
              //   underlying: this.props.underlying,
              //   type: this.props.type,
              //   expiry: this.props.expiry,
              // },
              // search: JSON.stringify(
              //   "underlying" +
              //     ":" +
              //     this.props.underlying +
              //     "," +
              //     "type" +
              //     ":" +
              //     this.props.type +
              //     "," +
              //     "expiry" +
              //     ":" +
              //     this.props.expiry
              // ),
              search: JSON.stringify(
                `${this.props.underlying},${this.props.type},${this.props.expiry}`
              ),
            }}
            target="_blank"
            id="searchSubmitBtn"
            type="button"
            className="btn btn-primary searchSubmitBtn"
            style={{ width: "190px" }}
          >
            Submit
          </Link>
          <Link
            to="/tools/warrantsearch"
            type="button"
            className="btn btn-primary"
            style={{ width: "190px" }}
          >
            View all underlyings
          </Link>
        </div>
      </>
    );
  }

  renderPage() {
    let initData = null;
    if (
      typeof this.props.initData !== "undefined" &&
      this.props.initData !== null
    ) {
      initData = this.props.initData;
    }

    const underlyingList = this.getUnderlying(initData);
    const initUnderlying = this.getInitUnderlying(underlyingList);

    const expiry = this.getExpiry(initData);
    const issuer = this.getIssuer(initData);
    const initIssuer = this.getInitIssuer(issuer);
    const initExpiry = {
      label: this.props.initExpiry,
      value: this.props.initExpiry,
    };
    const types = {
      label: this.props.initType,
      value: this.props.initType,
    };
    return (
      <div className="row">
        <div className="col-sm-4">
          <Dropdown
            source="underlying"
            placeholder="select underlying"
            default={initUnderlying}
            options={underlyingList}
            onSelectionChange={(source, selected) =>
              this.onSelectionChange(source, selected)
            }
          />
          <div className="hidden-sm hidden-xs">
            <Dropdown
              source="maturity"
              placeholder="select maturity"
              default={maturity[0]}
              options={maturity}
              onSelectionChange={(source, selected) =>
                this.onSelectionChange(source, selected)
              }
            />
            <Dropdown
              source="expiry"
              placeholder="select expiry"
              default={initExpiry}
              options={expiry}
              onSelectionChange={(source, selected) =>
                this.onSelectionChange(source, selected)
              }
            />
          </div>
        </div>
        <div className="col-sm-4">
          <Dropdown
            source="type"
            placeholder="select Call/Put"
            default={types}
            options={type}
            onSelectionChange={(source, selected) =>
              this.onSelectionChange(source, selected)
            }
          />
          <div className="hidden-sm hidden-xs">
            <Dropdown
              source="effectiveGearing"
              placeholder="select effective gearing"
              default={effectiveGearing[0]}
              options={effectiveGearing}
              onSelectionChange={(source, selected) =>
                this.onSelectionChange(source, selected)
              }
            />
            <Dropdown
              source="indicator"
              placeholder="select indicator"
              default={indicator[0]}
              options={indicator}
              onSelectionChange={(source, selected) =>
                this.onSelectionChange(source, selected)
              }
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="hidden-sm hidden-xs">
            <Dropdown
              source="moneyness"
              placeholder="select moneyness"
              default={moneyness[0]}
              options={moneyness}
              onSelectionChange={(source, selected) =>
                this.onSelectionChange(source, selected)
              }
            />
          </div>
          {/* <Dropdown
            source="issuer"
            placeholder="select issuers"
            default={initIssuer}
            options={issuer}
            onSelectionChange={(source, selected) =>
              this.onSelectionChange(source, selected)
            }
          /> */}


          <Select
            ref="selectRef"
            placeholder="select issuers"
            // default='MACQ'
            options={this.state.issuerList}
            components={{ MenuList }}
            onMenuClose={() => this.onMenuClose()}
            onSelectionChange={(source, selected, index) =>
              this.onSelectionChange(source, selected, index)
            }
            styles={{
              option: (provided, state) => ({
                ...provided,
                textAlign: "center",
                backgroundColor: '#FFFFFF',
                cursor: 'pointer',
                color: '#000',
                ':hover': {
                  backgroundColor: 'red',
                },
              }),
              valueContainer: (provided) => ({
                ...provided,
                justifyContent: 'center',
                opacity: 1,
                marginLeft: '25px'
              })
            }}
          />
          <div style={{ marginTop: '12px' }}>
            <button
              className="btn btn-primary"
              style={{ width: "100%", height: "35px" }}
              onClick={this.onSubmit.bind(this)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.isHome()) {
      return this.renderHome();
    } else {
      return this.renderPage();
    }
  }
}

class IconMeanning extends Component {
  render() {
    const highestliquidityTooltip = (
      <div>
        <p>
          <strong>Highest liquidity:</strong> Highlights the Macquarie warrant for each underlying with the largest volumes on the current bid and offer.
        </p>
      </div>
    );
    const nearexpiryTooltip = (
      <div>
        <p>
          <strong>Near expiry:</strong> Macquarie warrants with less than 1 month to expiry. These tend to be very high risk, so please trade with caution.
        </p>
      </div>
    );
    const responsiveTooltip = (
      <div>
        <p>
          <strong>Responsive:</strong> Indicates the Macquarie warrant that is most sensitive to the underlying price movements for each underlying. This is usually the one with the smallest number in the “Sensitivity” field.
        </p>
      </div>
    );
    const hotdwTooltip = (
      <div>
        <p>
          <strong>Trending warrants:</strong> The Trending warrants are chosen based on the warrants’ gearing, liquidity, sensitivity and are on tighter spreads
        </p>
      </div>
    );
    const lowTimeDecayTooltip = (
      <div>
        <p>
          <strong>Low time decay:</strong> Warrants with this indicator have relatively lower time decay, meaning lower holding costs for investors. Generally, all else constant, longer dated Warrants have lower time decay than shorter dated Warrants and vice versa.
        </p>
      </div>
    );
    const soldOutTooltip = (
      <div>
        <p>
          <strong>Inventory sold-out:</strong> Warrants with this indicator are either sold out or close to being sold out, meaning the market maker may no longer provide an offer price or have widened spreads by raising the offer price. Existing holders are not disadvantaged as the bid price is still maintained at its fair price. However, please exercise caution if you are looking to buy this Warrant as the market price may be inflated by other investors queuing in the market. Do check the Live Matrix for the warrant’s fair bid price.
        </p>
      </div>
    );
    return (
      <ul className="indicator-list list-unstyled list-inline">
        <li>
          <div
            className="indicator-item small"
            data-tip
            data-for="indicator-highestliquidity-tooltip"
          >
            <span className="icon-liquidity indicator-item-icon"></span>
            <span className="ttip" data-target="highestliquidity">
              Highest liquidity
            </span>
          </div>
          <ReactTooltip
            id="indicator-highestliquidity-tooltip"
            className="search-tooltip"
            delayHide={100}
            place="right"
            type="warning"
            effect="solid"
          >
            {highestliquidityTooltip}
          </ReactTooltip>
        </li>
        <li>
          <div
            className="indicator-item small"
            data-tip
            data-for="indicator-nearexpiry-tooltip"
          >
            <span className="icon-expiry indicator-item-icon"></span>
            <span className="ttip" data-target="nearexpiry">
              Near expiry
            </span>
          </div>
          <ReactTooltip
            id="indicator-nearexpiry-tooltip"
            className="search-tooltip"
            delayHide={100}
            place="right"
            type="warning"
            effect="solid"
          >
            {nearexpiryTooltip}
          </ReactTooltip>
        </li>

        <li>
          <div
            className="indicator-item small"
            data-tip
            data-for="indicator-responsive-tooltip"
          >
            <span className="icon-responsive indicator-item-icon"></span>
            <span className="ttip" data-target="responsive">
              Responsive
            </span>
          </div>
          <ReactTooltip
            id="indicator-responsive-tooltip"
            className="search-tooltip"
            delayHide={100}
            place="right"
            type="warning"
            effect="solid"
          >
            {responsiveTooltip}
          </ReactTooltip>
        </li>
        <li>
          <div
            className="indicator-item small"
            data-tip
            data-for="indicator-hotdw-tooltip"
          >
            <span className="icon-hotdw indicator-item-icon"></span>
            <span className="ttip" data-target="hotdw">
              Trending warrants
            </span>
          </div>
          <ReactTooltip
            id="indicator-hotdw-tooltip"
            className="search-tooltip"
            delayHide={100}
            place="right"
            type="warning"
            effect="solid"
          >
            {hotdwTooltip}
          </ReactTooltip>
        </li>
        <li>
          <div
            className="indicator-item small"
            data-tip
            data-for="indicator-soldOut-tooltip"
          >
            <span className="icon-soldOut indicator-item-icon"></span>
            <span className="ttip" data-target="soldOut">
              Inventory sold-out
            </span>
          </div>
          <ReactTooltip
            id="indicator-soldOut-tooltip"
            className="search-tooltip"
            delayHide={100}
            place="right"
            type="warning"
            effect="solid"
          >
            {soldOutTooltip}
          </ReactTooltip>
        </li>
        <li>
          <div
            className="indicator-item small"
            data-tip
            data-for="indicator-lowtimedecay-tooltip"
          >
            <span className="icon-lowtimedecay indicator-item-icon"></span>
            <span className="ttip" data-target="lowtimedecay">
              Low time decay
            </span>
          </div>
          <ReactTooltip
            id="indicator-lowtimedecay-tooltip"
            className="search-tooltip"
            delayHide={100}
            place="right"
            type="warning"
            effect="solid"
          >
            {lowTimeDecayTooltip}
          </ReactTooltip>
        </li>
      </ul>
    );
  }
}

class TableResult extends Component {
  onSorting(field, isAsc) {
    this.props.onSorting(field, isAsc);
  }

  getRowData(size) {
    const rows = [];
    if (this.props.config !== null && this.props.result !== null) {
      if ("fields" in this.props.config && this.props.result.length > 0) {
        this.props.result.forEach((data, index) => {
          let td = [];
          this.props.config.fields.forEach((field) => {
            if (this.props.config.columnShow(size, field)) {
              td.push(field.columnJsx(data, index));
            }
          });
          const tr = (
            <tr
              className={"rowtmpl-" + size + " text-bold"}
              key={index}
              idx={index}
            >
              {td}
            </tr>
          );
          rows.push(tr);
        });
      }
    }
    return rows;
  }

  getRowHeader2(size, visible = true) {
    const th = [];
    if (this.props.config !== null) {
      this.props.config.fields.forEach((field) => {
        if (this.props.config.columnShow(size, field)) {
          th.push(this.props.config.columnHeader2Jsx(size, field, visible));
        }
      });
    }
    const style = visible ? null : { height: "0px", visibility: "hidden" };
    return (
      <tr className="result-hdr2" key={"result-hdr2-" + size} style={style}>
        {th}
      </tr>
    );
  }

  getRowDataSort(size) {
    const th = [];
    if (this.props.config !== null) {
      this.props.config.fields.forEach((field) => {
        if (this.props.config.columnShow(size, field)) {
          th.push(
            this.props.config.columnSortJsx(
              size,
              field,
              this.onSorting.bind(this)
            )
          );
        }
      });
    }
    return (
      <tr className="result-sort" key={"result-sort-" + size}>
        {th}
      </tr>
    );
  }
  getUpOrDownClass(value) {
    let upDown = " val ";
    if (value.indexOf("-") > -1) {
      upDown += "down ";
    } else if (value.indexOf("+") > -1) {
      upDown += "up ";
    }
    return upDown
  }
  render() {
    return (
      <div id="resulttable" className="result" style={{ overflowX: 'scroll' }}>
        <table
          id="faketable"
          className="hidden-sm hidden-xs"
          style={{ tableLayout: "fixed", marginBottom: "-1px" }}
        >
          <thead>
            {/* {this.getRowHeader2("lg", false)} */}
            <tr className="result-hdr2" style={{ height: '0px', visibility: 'hidden' }}>
              <th className="bgcolor-04" style={{ width: '5.15%' }}>

              </th>
              <th className="bgcolor-04 noborder" style={{ width: '4%' }}></th>
              <th className="bgcolor-04" style={{ width: '11%' }}>

              </th>
              <th className="bgcolor-04" style={{ width: '3%' }}>

              </th>
              <th className="bgcolor-04" style={{ width: '6.1%' }}>

              </th>
              <th className="bgcolor-04" style={{ width: '4.1%' }}>

              </th>
              <th className="bgcolor-04" style={{ width: '6.1%' }}>

              </th>
              <th className="bgcolor-04" style={{ width: '6.45%' }}>

              </th>
              <th className="bgcolor-05" style={{ width: '6.1%' }}>

              </th>
              <th className="bgcolor-05" style={{ width: '5.1%' }}>

              </th>
              <th className="bgcolor-05" style={{ width: '6.1%' }}>

              </th>
              <th className="bgcolor-05" style={{ width: '5.1%' }}>

              </th>
              <th className="bgcolor-05" style={{ width: '6.1%' }}>

              </th>
              <th className="bgcolor-05" style={{ width: '5%' }}>

              </th>
              <th className="bgcolor-06" style={{ width: '5.1%' }}>

              </th>
              <th className="bgcolor-06" style={{ width: '6.0%' }}>

              </th>
              <th className="bgcolor-06" style={{ width: '4.6%' }}>

              </th>
              <th className="bgcolor-06" style={{ width: '6.0%' }}>

              </th>
              <th className="bgcolor-06" style={{ width: '6.0%' }}>

              </th>
            </tr>
            <tr className="result-hdr1">
              <th className="bgcolor-04" colSpan="8">
                GENERAL
              </th>
              <th className="bgcolor-05" colSpan="6">
                MARKET
              </th>
              <th className="bgcolor-06" colSpan="5">
                WARRANT
              </th>
            </tr>
          </thead>
        </table>
        <table id="resulttable-lg" className="table-fixed">
          <thead>
            <tr className="result-hdr2 visible-lg  visible-md" >
              <th className="bgcolor-04" style={{ width: '5.15%' }}>
                <div
                  data-tip
                  data-for="indicator-Warrant-tooltip"
                >
                  <span className="ttip" data-target="wcode">Warrant<br />code</span>
                  <ReactTooltip
                    id="indicator-Warrant-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Warrant code: </strong>
                        Each warrant has an unique 4-digit identification code,
                        <a href="/InvestorAcademy/howarewarrantsnamed" target="_blank"> click here </a>
                        for a more detailed explanation.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-04 noborder" style={{ width: '4%' }}></th>
              <th className="bgcolor-04" style={{ width: '11%' }}>
                <div
                  data-tip
                  data-for="indicator-underlying-tooltip"
                >
                  {/* <span className="ttip" data-target="underlying">{size=='xs'?'Underlying name':'Underlying'}</span> */}
                  <span className="ttip" data-target="underlying">Underlying</span>
                  <ReactTooltip
                    id="indicator-underlying-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Underlying: </strong>
                        The stock or index over which the Warrant is listed.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-04" style={{ width: '3%' }}>
                <div
                  data-tip
                  data-for="indicator-type-tooltip"
                >
                  <span className="ttip" data-target="type">Type</span>
                  <ReactTooltip
                    id="indicator-type-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Type: </strong>
                        Calls increase in value as share price rises, Puts increase in value as share price falls.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-04" style={{ width: '6.1%' }}>
                <div
                  data-tip
                  data-for="indicator-exercisePrice-tooltip"
                >
                  <span className="ttip" data-target="exerciseprice">Exercise<br />price<br />(Strike)</span>
                  <ReactTooltip
                    id="indicator-exercisePrice-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Exercise price: </strong>
                        The price at which you can buy (calls) or sell (puts) the underlying
                        <br />
                        share or index at expiry.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-04" style={{ width: '4.1%' }}>
                <div
                  data-tip
                  data-for="indicator-dwps-tooltip"
                >
                  <span className="ttip" data-target="wps">WPS</span>
                  <ReactTooltip
                    id="indicator-dwps-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>WPS: </strong>
                        The number of warrants that entitles you to one unit of the underlying share or index at expiry.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-04" style={{ width: '6.1%' }}>
                <div
                  data-tip
                  data-for="indicator-date-tooltip"
                >
                  <span className="ttip" data-target="lasttradingdate">Last<br />trading<br />date</span>
                  <ReactTooltip
                    id="indicator-date-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Last trading date: </strong>
                        The last day to buy or sell the warrant.  This date is always the 5th trading day prior to the expiry date.  For Hong Kong underlyings, the last trading date will be brought forward if the 5th trading day prior to expiry falls on a Hong Kong holiday.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-04" style={{ width: '6.45%' }}>
                <div
                  data-tip
                  data-for="indicator-isr-tooltip"
                >
                  <span className="ttip" data-target="issuer">Issuer</span>
                  <ReactTooltip
                    id="indicator-isr-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Issuer: </strong>
                        The Bank or Securities company that issues the Warrant.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-05" style={{ width: '6.1%' }}>
                <div
                  data-tip
                  data-for="indicator-MARKET-tooltip"
                >
                  <span className="ttip" data-target="bidvolume">Bid<br />volume<br />('000)</span>
                  <ReactTooltip
                    id="indicator-MARKET-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Bid volume: </strong>
                        The volume provided at the current highest bid price for the Warrant.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-05" style={{ width: '5.1%' }}>
                <div
                  data-tip
                  data-for="indicator-bp-tooltip"
                >
                  <span className="ttip" data-target="bid">Bid<br />(SGD)</span>
                  <ReactTooltip
                    id="indicator-bp-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Bid: </strong>
                        The highest current buy price of the Warrant. This is the value that most accurately reflects the 'value' of the Warrant at any point in time.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-05" style={{ width: '6.1%' }}>
                <div
                  data-tip
                  data-for="indicator-ap-tooltip"
                >
                  <span className="ttip" data-target="ask">Ask<br />(SGD)</span>
                  <ReactTooltip
                    id="indicator-ap-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Ask: </strong>
                        The lowest current sell price for the Warrant.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-05" style={{ width: '5.1%' }}>
                <div
                  data-tip
                  data-for="indicator-av-tooltip"
                >
                  <span className="ttip" data-target="askvolume">Ask<br />volume<br />('000)</span>
                  <ReactTooltip
                    id="indicator-av-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Ask volume: </strong>
                        The volume provided at the current lowest sell price for the Warrant.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-05" style={{ width: '6.1%' }}>
                <div
                  data-tip
                  data-for="indicator-tv-tooltip"
                >
                  <span className="ttip" data-target="tradedvolume">Traded volume<br />('000)</span>
                  <ReactTooltip
                    id="indicator-tv-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Traded volume: </strong>
                        Total volume traded in the Warrant for the current day. A Warrant that has not traded for the day does not necessarily mean that it is not liquid.
                        <a href="/InvestorAcademy/liquidityvstradedvolume" target="_blank"> Click here </a>
                        for more info about Warrant and liquidity.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-05" style={{ width: '5%' }}>
                <div
                  data-tip
                  data-for="indicator-pc-tooltip"
                >
                  <span className="ttip" data-target="pricechange">Price change<br />(%)</span>
                  <ReactTooltip
                    id="indicator-pc-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p style={{ textAlign: 'left' }}>
                        <strong>Price change: </strong>
                        Current bid price for the Warrant compared to the Prior (previous days close). The bid price of the Warrant 1 minute before close of trading at 5.00pm, will be used for the Prior. For more info
                        <a href="/InvestorAcademy/howarewarrantspriced" target="_blank" style={{ textAlign: 'left' }}> click here </a>
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-06" style={{ width: '5.1%' }}>
                <div
                  data-tip
                  data-for="indicator-dlt-tooltip"
                >
                  <span className="ttip" data-target="delta">Delta<br />(%)</span>
                  <ReactTooltip
                    id="indicator-dlt-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Delta: </strong>
                        Estimates how a warrant moves in relation to the underlying stock/index. Puts have negative deltas because their prices increase as the underlying stock/index falls. Note, if your warrant has a WPS that this will need to be taken into account.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-06" style={{ width: '6.0%' }}>
                <div
                  data-tip
                  data-for="indicator-ss-tooltip"
                >
                  <span className="ttip" data-target="sensitivity">Sensitivity</span>
                  <ReactTooltip
                    id="indicator-ss-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Sensitivity: </strong>
                        Shows you how much the underlying stock or index must move for your warrant to move one "tick" or "Spread". For warrants that cost less than $0.200, one tick is $0.001.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-06" style={{ width: '4.6%' }}>
                <div
                  data-tip
                  data-for="indicator-eg-tooltip"
                >
                  <span className="ttip" data-target="effectivegearing">Eff. gearing<br />(x)</span>
                  <ReactTooltip
                    id="indicator-eg-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p style={{ textAlign: 'left' }}>
                        <strong>Effective gearing: </strong>
                        Indicates your leveraged exposure. An effective gearing of 5X tells you a 1% move in the underlying stock/index results in an approximate 5% move in your Warrant price. <a href="/InvestorAcademy/effectivegearing" target="_blank">Click here </a>to learn more about effective gearing.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-06" style={{ width: '6.0%' }}>
                <div
                  data-tip
                  data-for="indicator-iv-tooltip"
                >
                  <span className="ttip" data-target="impliedvolatility">Volatility<br />(%)</span>
                  <ReactTooltip
                    id="indicator-iv-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p style={{ textAlign: 'left' }}>
                        <strong>Volatility: </strong>
                        the higher the price of the Warrant. Relates to the volatility and risk of the underlying share/Index.<a href="/InvestorAcademy/impliedvolatility" target="_blank"> Click here </a>to learn more about implied volatility.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="bgcolor-06" style={{ width: '6.0%' }}>
                <div
                  data-tip
                  data-for="indicator-WARRANT-tooltip"
                >
                  <span className="ttip" data-target="estimateddecay">Estimated decay</span>
                  <ReactTooltip
                    id="indicator-WARRANT-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Estimated decay: </strong>
                        {/* The estimated average time decay for the warrant over the next 5 days. */}
                        Estimated drop in warrant value per day
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
            </tr>
            <tr className="result-sort visible-lg  visible-md" >
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('wcode', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('wcode', 'asc') }}></span>
              </th>
              <th></th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('ucode', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('ucode', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('type', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('type', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('ep', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('ep', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('dwps', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('dwps', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('ldate', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('ldate', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('isr', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('isr', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('bv', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('bv', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('bp', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('bp', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('ap', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('ap', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('av', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('av', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('tv', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('tv', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('pc', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('pc', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('dlt', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('dlt', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('ss', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('ss', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('eg', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('eg', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort glyphicon glyphicon-triangle-bottom result-sort-down"
                  onClick={() => { this.onSorting('iv', 'desc') }}></span>
                <span className='icon-sort glyphicon glyphicon-triangle-top result-sort-up'
                  onClick={() => { this.onSorting('iv', 'asc') }}></span>
              </th>
            </tr>

            <tr className="visible-sm">
              <th className="bgcolor-04" colSpan="7">
                GENERAL
              </th>
              <th className="bgcolor-05" colSpan="6">
                MARKET
              </th>
              <th className="bgcolor-06" colSpan="2">
                WARRANT
              </th>
            </tr>
            <tr className="result-hdr2 visible-sm" >
              <th className="bgcolor-04" style={{ width: '5.15%' }}>
                Warrant<br />code
              </th>
              <th className="bgcolor-04 noborder" style={{ width: '4%' }}></th>
              <th className="bgcolor-04" style={{ width: '3%' }}>
                Type
              </th>
              <th className="bgcolor-04" style={{ width: '6.1%' }}>
                Ex.<br />price<br />
              </th>
              <th className="bgcolor-04" style={{ width: '4.1%' }}>
                Ex.<br />ratio
              </th>
              <th className="bgcolor-04" style={{ width: '6.1%' }}>
                Last<br />trading<br />date
              </th>
              <th className="bgcolor-04" style={{ width: '6.45%' }}>
                Issuer
              </th>
              <th className="bgcolor-05" style={{ width: '6.1%' }}>
                Bid<br />volume<br />('000)
              </th>
              <th className="bgcolor-05" style={{ width: '5.1%' }}>
                Bid<br />(SGD)
              </th>
              <th className="bgcolor-05" style={{ width: '6.1%' }}>
                Ask<br />(SGD)
              </th>
              <th className="bgcolor-05" style={{ width: '5.1%' }}>
                Ask<br />volume<br />('000)
              </th>
              <th className="bgcolor-05" style={{ width: '6.1%' }}>
                Traded <br />volume<br />('000)
              </th>
              <th className="bgcolor-05" style={{ width: '5%' }}>
                Price change<br />(%)
              </th>
              <th className="bgcolor-06" style={{ width: '4.6%' }}>
                Eff. gearing<br />(x)
              </th>
              <th className="bgcolor-06" style={{ width: '6.0%' }}>
                Volatility<br />(%)
              </th>
            </tr>
            <tr className="result-sort visible-sm" >
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('wcode', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('wcode', 'asc') }}></span>
              </th>
              <th></th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('type', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('type', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('ep', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('ep', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('dwps', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('dwps', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('ldate', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('ldate', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('isr', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('isr', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('bv', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('bv', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('bp', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('bp', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('ap', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('ap', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('av', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('av', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('tv', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('tv', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('pc', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('pc', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('eg', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('eg', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('iv', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('iv', 'asc') }}></span>
              </th>
            </tr>

            <tr className="result-hdr2 visible-xs">
              <th className="col-clickable">
                Warrant<br />code
              </th>
              <th className="col-clickable">
                <div
                  data-tip
                  data-for="indicator-underlying-tooltip"
                >
                  {/* <span className="ttip" data-target="underlying">{size=='xs'?'Underlying name':'Underlying'}</span> */}
                  <span className="ttip" data-target="underlying">Underlying</span>
                  <ReactTooltip
                    id="indicator-underlying-tooltip"
                    className="tools-search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                  >
                    <div>
                      <p>
                        <strong>Underlying: </strong>
                        The stock or index over which the Warrant is listed.
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </th>
              <th className="col-clickable">type</th>
              <th className="col-clickable">Strike</th>
              <th className="col-clickable">Last<br />trading<br />date</th>
              <th className="col-clickable">Bid</th>
              <th className="col-clickable">Traded volume<br />('000)</th>
            </tr>
            <tr className="result-sort visible-xs" key={"result-sort-" + 'sm'}>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('wcode', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('wcode', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('ucode', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('ucode', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('type', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('type', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('ep', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('ep', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('ldate', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('ldate', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('bp', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('bp', 'asc') }}></span>
              </th>
              <th>
                <span className="icon-sort-xs glyphicon glyphicon-triangle-bottom"
                  onClick={() => { this.onSorting('tv', 'desc') }}></span>
                <span className='icon-sort-xs glyphicon glyphicon-triangle-top'
                  onClick={() => { this.onSorting('tv', 'asc') }}></span>
              </th>
            </tr>
          </thead>

          <tbody>
            {
              !this.props.isLoading && this.props.result && this.props.result.map((value, index) => {
                return (
                  <tr
                    className={"rowtmpl-lg" + ' ' + " text-bold"}
                  >
                    <td >
                      <a href={"/tools/livematrix/" + value.dwSymbol}>{value.dwSymbol}</a>
                    </td>
                    <td className="hidden-xs">
                      {
                        value.HIGHLQDY && <span className="indicator-item-icon ttip icon-liquidity" data-target="highestliquidity" data-tip
                          data-for="li-tooltip"
                          data-hasqtip="107" key={"icon-liquidity-" + index}>
                          <ReactTooltip
                            id="li-tooltip"
                            className="search-tooltip"
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                          >
                            <div style={{ fontFamily: "'HelveticaNeueLight','HelveticaNeue','Roboto', Arial, sans-serif" }}>
                              <p>
                                <strong style={{ fontWeight: 600 }}>Highest liquidity:</strong> Highlights the Macquarie warrant for each underlying with the largest volumes on the current bid and offer.
                              </p>
                            </div>
                          </ReactTooltip>
                        </span>
                      }
                      {
                        value.LongestDated && <span className="indicator-item-icon ttip icon-lowtimedecay" data-target="lowtimedecay" data-tip
                          data-for="lowtimedecay-tooltip"
                          data-hasqtip="107" key={"icon-lowtimedecay-" + index}>
                          <ReactTooltip
                            id="lowtimedecay-tooltip"
                            className="search-tooltip"
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                          >
                            <div>
                              <p>
                                <strong>Low time decay:</strong> Warrants with this indicator have relatively lower time decay, meaning lower holding costs for investors. Generally, all else constant, longer dated Warrants have lower time decay than shorter dated Warrants and vice versa.
                              </p>
                            </div>
                          </ReactTooltip>
                        </span>
                      }
                      {
                        value.SoldOut && <span className="indicator-item-icon ttip icon-soldOut" data-target="soldOut" data-tip
                          data-for="soldOut-tooltip"
                          data-hasqtip="107" key={"icon-soldOut-" + index}>
                          <ReactTooltip
                            id="soldOut-tooltip"
                            className="search-tooltip"
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                          >
                            <div>
                              <p>
                                <strong>Inventory sold-out:</strong> Warrants with this indicator are either sold out or close to being sold out, meaning the market maker may no longer provide an offer price or have widened spreads by raising the offer price. Existing holders are not disadvantaged as the bid price is still maintained at its fair price. However, please exercise caution if you are looking to buy this SW as the market price may be inflated by other investors queuing in the market. Do check the Live Matrix for the warrant’s fair bid price.
                              </p>
                            </div>
                          </ReactTooltip>
                        </span>
                      }
                      {
                        value.APPCHEXPRY && <span className="indicator-item-icon ttip icon-expiry" data-target="nearexpiry" data-tip
                          data-for="ne-tooltip"
                          data-hasqtip="" key={"icon-expiry-" + index}>
                          <ReactTooltip
                            id="ne-tooltip"
                            className="search-tooltip"
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                          >
                            <div style={{ fontFamily: "'HelveticaNeueLight','HelveticaNeue','Roboto', Arial, sans-serif" }}>
                              <p>
                                <strong style={{ fontWeight: 600 }}>Near expiry:</strong>Macquarie warrants with less than 1 month to expiry. These tend to be very high risk, so please trade with caution.
                              </p>
                            </div>
                          </ReactTooltip>
                        </span>
                      }
                      {
                        value.HIGHRESP && <span className="indicator-item-icon ttip icon-responsive" data-target="responsive" data-tip
                          data-for="re-tooltip"
                          data-hasqtip="" key={"icon-responsive-" + index}>
                          <ReactTooltip
                            id="re-tooltip"
                            className="search-tooltip"
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                          >
                            <div style={{ fontFamily: "'HelveticaNeueLight','HelveticaNeue','Roboto', Arial, sans-serif" }}>
                              <p>
                                <strong style={{ fontWeight: 600 }}>Responsive:</strong> Indicates the Macquarie warrant that is most sensitive to the underlying price movements for each underlying. This is usually the one with the smallest number in the “Sensitivity” field.
                              </p>
                            </div>
                          </ReactTooltip>
                        </span>
                      }
                      {
                        value.HOTLIST && <span className="indicator-item-icon ttip icon-hotlist" data-target="hotdw" data-tip
                          data-for="hot-tooltip"
                          data-hasqtip="40" key={"icon-hotlists-" + index}>
                          <ReactTooltip
                            id="hot-tooltip"
                            className="search-tooltip"
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                          >
                            <div style={{ fontFamily: "'HelveticaNeueLight','HelveticaNeue','Roboto', Arial, sans-serif" }}>
                              <p>
                                <strong style={{ fontWeight: 600 }}>Trending warrants:</strong> The Trending warrants are chosen based on the warrants’ gearing, liquidity, sensitivity and are on tighter spreads
                              </p>
                            </div>
                          </ReactTooltip>
                        </span>
                      }
                    </td>
                    <td className="visible-lg  visible-md visible-xs">
                      <a href={'/tools/underlying/' + value.underlyingSymbol}>{MQSGUtil.getUnderlyingTickerDisplayName(value.underlying_ric, value.underlyingSymbol)}</a>
                    </td>
                    <td >{value.type}</td>
                    <td >{value.underlying_name == 'TENCENT' ? value.exercise_price_cal : value.exercisePrice}</td>
                    <td className="hidden-xs">{value.underlying_name == 'TENCENT' ? Number(value.dwps).toFixed(3) : value.dwps}</td>
                    <td >{value.ltDate}</td>
                    <td className="bordered hidden-xs">{value.issuer}</td>

                    <td className="hidden-xs">{value.bidVolume_f}</td>
                    <td >{value.bidPrice_f}</td>
                    <td className="hidden-xs">{value.askPrice_f}</td>
                    <td className="hidden-xs">{value.askVolume_f}</td>
                    <td >{value.tradeVolume_f}</td>
                    <td className={'bordered visible-lg  visible-md hidden-xs' + this.getUpOrDownClass(value.priceChange_f)}>{value.priceChange_f}</td>

                    <td className={'hidden-xs ' + this.getUpOrDownClass(value.delta)}>{value.delta}</td>
                    <td className="visible-lg  visible-md hidden-xs">{value.sensitivity}</td>
                    <td className="hidden-xs">{value.effectiveGearing}</td>
                    <td className="hidden-xs">{value.impliedVolalitiy}</td>
                    <td className="visible-lg  visible-md hidden-xs">{value.avg_decay > 0 ? '-' + value.avg_decay : value.avg_decay}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        {this.props.isLoading && (
          <div className="loader-div">
            <LoaderSpin isLoading={this.props.isLoading} isVisible />
          </div>
        )}
        <p className="sorttip visible-xs">Click the column header to sort.</p>
        <div className="result-disclaimer hidden-xs hidden-sm">
          <p className="small">
            ** The Price Change (%) is calculated using the current bid price
            for the warrant compared to the previous day's closing bid price. At
            the end of each trading day, we capture the bid price of the warrant
            slightly before the close of trading and use this to represent the
            'closing price'. The relevant times are: 4:58pm for Singapore
            underlyings, Nikkei warrants and S&amp;P 500® warrants, 4:28pm for
            HSI warrants. This will provide the most accurate reference point
            for investors to track price movements of the Warrant. For more info{" "}
            <a
              target="_blank"
              style={{ color: '#0675C2' }}
              href="/InvestorAcademy/closingtimestonote"
            >
              click here
            </a>
            .
            <br />
            <br />* The exercise prices and WarrantPS in the above table in some
            cases have been rounded to fit the page. Please click on the code to
            go to the Warrant terms page for the full rounding.
          </p>
          <hr />
          <p className="small">
            All market data is delayed 15 mins unless otherwise indicated by
            timestamp.
          </p>
        </div>
      </div>
    );
  }
}
export default withRouter(WarrantSearch);
// 1. Data
//      1.1 fetch
//      1.2 estimated decay (rounding ?)
//      1.3 Issuer (MBL) > display as (MACQ) ?
// 2. Sorting
// 3. tooltip
// xs (<768)
// sm (>=768)
// md (>=992)
// lg (>=1200)
